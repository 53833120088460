import React from "react"
import { Link } from 'gatsby'
import styled from "styled-components"

const FullPro = styled.div`
    text-align: center;
    width: 100%;
    color: #fff;
    padding-bottom: 4rem;
    border-top: #00B227 solid 3rem;
    border-bottom: #00B227 solid 1rem;
    margin-bottom: 5rem;

`

const Blurb = styled.h3`
    color: #333;
    margin: 3rem 0rem 3rem 0rem;
    font-size: 1.7rem;
    line-height: 1.4;
`

const Anchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #0D5C00;
`

const PromoButton = styled.a`
    text-align: center;
    padding: 1.2rem 4rem;
    background-color: #eee;
    font-weight: 600;
    color: #333;
    font-size: 1.6rem;
    text-decoration: none;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    &:hover {
        background-color:#0D5C00;
        color: #fff;
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
`
const SmallPromo = () => {
    return (
        <FullPro>
            <Blurb>Receive daily feedback and weekly meetings with Chris Courtney by signing up for monthly mentorship today!</Blurb> 
            <PromoButton href="/mentorship">Sign up today!</PromoButton>
        </FullPro>
    )
}

export default SmallPromo