import React from 'react'
import { Link, graphql } from 'gatsby'
// import Img from "gatsby-image"
import styled from "styled-components"

import Layout from '../components/Layout'
import SmallPromo from "../components/smallpromo"
import Webinar from "../components/webinar"

import SEO from '../components/seo'
import "../components/blog.css"

const Chapter = styled.h1`
  font-size: 1.4rem;
  font-weight: 800;
  letter-spacing: 6px;
  line-height: 1.1;
  margin: 2rem 0 1rem 0;
  width: 80%;
  text-transform: uppercase;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const BlogMain = styled.h1`
  font-size: 6rem;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.1;
  margin: 0 0 -.5rem 0;
  width: 80%;
  @media (max-width: 600px) {
    font-size: 4rem;
    width: 100%;
  }
`

const BlogSub = styled.h2`
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  margin: 2rem 0 0 0;
  width: 80%;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const Author = styled.p`
  font-size: 1.8rem;
  font-weight: 600;
  margin: 7rem 0 -.5rem 0;
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    
    return (
        <Layout location={this.props.location} title={siteTitle}>
          <SEO 
            title={post.frontmatter.title} 
            description={post.excerpt} 
            image={post.frontmatter.socialimage}
          />
          {post.frontmatter.image
            && (
            <img src={post.frontmatter.image} alt="illustration"/> 
            )
          }         
          <Chapter>{post.frontmatter.exercise} {post.frontmatter.chapter}</Chapter>
          <BlogMain>{post.frontmatter.title}</BlogMain>
          <BlogSub>{post.frontmatter.sub}</BlogSub>
          <Author>
            By {post.frontmatter.author}
          </Author>
          <p className="date">Updated {post.frontmatter.date}</p>
          <div className="post" dangerouslySetInnerHTML={{ __html: post.html }} />

          <SmallPromo />

          {/* <ul className="ulFlex">
            <li className="leftLink">
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul> */}
        </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        image 
        socialimage
        exercise
        chapter
        title
        date(formatString: "MMMM DD, YYYY")
        sub
        author
      }
    }
  }
`
