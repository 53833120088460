import React from "react"
import { Link } from 'gatsby'
import styled from "styled-components"

const FullPro = styled.div`
    background-color:rgba(13,92,0,1);
    width: 100%;
    color: #fff;
    display: flex;
    padding-bottom: none;
    border-bottom: #00B227 solid 1rem;
`

const ProLeft = styled.div`
    width: 60%;
    margin: 3rem 3rem 4rem 3rem;
    @media (max-width: 768px) {
        width: 100%;
    }
`
const ProRight = styled.div`
    width: 40%;
    align-self: flex-end;
    overflow: hidden;
    @media (max-width: 768px) {
        display: none;
    }

`
const ProImg = styled.img`
    width: 140%;
    margin-bottom: -10px;
    bottom: 0;
`

const Header = styled.h1`
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 800;
    line-height: .9;
`

const PromoP = styled.p`
    font-size: 1.6rem;
    line-height: 1.6;
    margin: 1rem 0 2rem 0;
`
const PromoButton = styled.a`
    padding: 8px 12px;
    background-color: #fff;
    color: #0D5C00;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    &:hover {
        background-color:#00B227;
        color: #fff;
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    }
`



const Webinar = () => {
    return (
        <FullPro>
            <ProLeft>
                <Header>Smarter design begins with research</Header>
                <PromoP>Join Chris Courtney on Wednesday as he covers the tools and methods you can use meet client requirements while solving problems in the name of the user.</PromoP>
                <PromoButton target="_blank" href="https://newpragmatic.typeform.com/to/NzCXjs">Reserve your seat</PromoButton>

            </ProLeft>
            <ProRight>
                <ProImg src={require("../../content/assets/promo-cut.png")} />
            </ProRight>
        </FullPro>
    )
}

export default Webinar